import React, { Fragment, useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Link from '@material-ui/core/Link';

import Layout from '../components/Layout';
import { Spacer } from '../templates/allgames';
import Contact from '../components/Contact';
import SearchContext, { withSearch } from '../components/TopBarGames/context';
import Carousel from '../components/Carousel';
import Slide from '../components/Carousel/Slide';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    minWidth: 300,
    minHeight: 205,
    textAlign: 'center',
    margin: 'auto',    
  },
}));



const DemoPage = (props) => {
  const classes = useStyles();
  const preventDefault = event => event.preventDefault();
  const [loading, setLoading] = useState(false)
  const [media, setMedia] = useState([])
  const change = useCallback((index) => {
    console.log('change', index)
  }, [])

  return (
    <Fragment> 
      <Spacer />
      <Carousel 
        controlsVisible
        perspective={35}
        display={5}
        autoplay
        beforeSlideChange={change}
        space={240}
        height={300}
        width={300}
        border={0}
        scaling={300}
        animationSpeed={500}
        autoplayTimeout={2000}
      >
        <Slide key={1}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={2}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={3}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={4}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={5}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={6}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={7}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={8}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={9}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={10}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={11}>
          <img src="https://placehold.it/360x270" />
        </Slide>
        <Slide key={12}>
          <img src="https://placehold.it/360x270" />
        </Slide>
      </Carousel>

      <Contact />      
    </Fragment>
  )
};



export default withSearch(DemoPage);
